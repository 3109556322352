import React, { Component } from 'react';
// import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import Navigation from '../components/Nav';
import NavigationEn from '../components/Nav.en';
import Footer from '../components/Footer';
import { useAllPostDataEn } from '../hooks/GetAllPostDataEn';

// import 'animate.css/animate.min.css';
import '../style/app.scss';

const HomepageData = () => {
	const data = useAllPostDataEn();
	return (
		<div>
			{data.allWordpressPost.nodes.map((post) => {
				const arrayOfWords = post.excerpt.split(' ', 50);
				const arrayOfWordsJoined = arrayOfWords.join(' ');
				return (
					<article key={post.id}>
						<ScrollAnimation animateIn="fadeIn" animateOnce>
							<div className="featured_image" style={{ display: 'block', width: '100%', height: '100%' }}>
								<Img
									// imgStyle={{ height: 600, objectPosition: 'center' }}
									style={{ height: '100%' }}
									imgStyle={{ objectFit: 'cover', objectPosition: 'center', width: '100%' }}
									fluid={post.featured_media.localFile.childImageSharp.fluid}
								/>
							</div>
						</ScrollAnimation>
						<div className="meta">
							<div className="hashtags">
								{post.categories
									? post.categories.map((singleTag, index) => (
											<span key={index}>
												<a className="tagLink" href={`/tag/${singleTag.slug}/`}>
													#{singleTag.name}
												</a>
											</span>
									  ))
									: ''}
							</div>
						</div>
						<div className="info">
							<Link to={`/${post.slug}`} className="permalink">
								<h3 className="postTitle">{post.title}</h3>
							</Link>
							<div className="bottomContent">
								{
									// Check if under 20 wordss
									arrayOfWords.length < 50 ? (
										<div
											dangerouslySetInnerHTML={{
												__html: arrayOfWordsJoined,
											}}
											className="excerpt"
										/>
									) : (
										<div
											dangerouslySetInnerHTML={{
												__html: `${arrayOfWordsJoined} ...`,
											}}
											className="excerpt"
										/>
									)
								}

								<Link to={`/${post.slug}`} className="permalinkBottom">
									Read More
									{/* <FontAwesomeIcon icon={faChevronRight} /> */}
								</Link>
							</div>
						</div>
					</article>
				);
			})}
		</div>
	);
};

class Homepage extends Component {
	render() {
		return (
			<>
				<NavigationEn />
				{/* <div className="hero_single">
					<div className="container">
						<div className="content">
							<h1>Pocetna</h1>
							<Link to="/" className="back_home">
								Nazad na početnu
							</Link>
						</div>
					</div>
				</div> */}
				<section className="blog">
					<div className="container fullWidthContainer">
						<div className="blog_grid">
							<HomepageData />
						</div>
					</div>
				</section>
				<Footer />
			</>
		);
	}
}

export default Homepage;
