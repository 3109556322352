import { useStaticQuery, graphql } from 'gatsby';

export const useAllPostDataEn = () => {
	const AllPostDataEn = useStaticQuery(
		graphql`
			query GET_ALL_POSTS_EN {
				allWordpressPost(filter: { wpml_current_locale: { eq: "en_US" } }) {
					nodes {
						excerpt
						title
						slug
						id
						date
						wordpress_id
						acf {
							featuredElements {
								cmDimensions
								elementId
								elementName
								elementTagline
								inchDimensions
								elementImage {
									localFile {
										childImageSharp {
											fixed {
												...GatsbyImageSharpFixed
											}
											fluid(maxWidth: 1920) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
							imageOfVideo {
								localFile {
									childImageSharp {
										fixed {
											...GatsbyImageSharpFixed
										}
										fluid(maxWidth: 1920) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							mainContentRepeater {
								contentBlock
								imageBlock {
									localFile {
										childImageSharp {
											fixed {
												...GatsbyImageSharpFixed
											}
											fluid(maxWidth: 1920) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
							youtubeVideoId
						}
						featured_media {
							localFile {
								childImageSharp {
									fluid(maxWidth: 1920) {
										...GatsbyImageSharpFluid
									}
									fixed {
										...GatsbyImageSharpFixed
									}
								}
							}
						}
						categories {
							name
							id
							slug
						}
						wpml_current_locale
						wpml_translations {
							href
							locale
							post_title
							wordpress_id
						}
					}
				}
			}
		`
	);
	return AllPostDataEn;
};
